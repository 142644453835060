import { render, staticRenderFns } from "./PartnersMobile.vue?vue&type=template&id=285b2a0e&"
import script from "./PartnersMobile.vue?vue&type=script&lang=js&"
export * from "./PartnersMobile.vue?vue&type=script&lang=js&"
import style0 from "./PartnersMobile.vue?vue&type=style&index=0&id=285b2a0e&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./PartnersMobile.vue?vue&type=custom&index=0&blockType=i18n&lang=yaml"
if (typeof block0 === 'function') block0(component)

export default component.exports