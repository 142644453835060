<!-- eslint-disable max-len -->
<i18n lang="yaml">
  en:
    title: <span class="color-green">How to install</span><br />your Ween thermostat?
    content:
      The Ween thermostat has been designed for an easy install. To assist you through the process, please follow the step-by-step installation guide.
    image:
      alt: Ween products
    install_guide:
      title: Installation guide
      link: /pdf/notice_ween_en-1.3.50.pdf
    compatibility_configurator:
      title: Test my compatibility
  fr:
    title: <span class="color-green">Comment installer</span><br />votre thermostat Ween ?
    content:
      Le thermostat Ween a été pensé pour que vous puissiez très facilement l'installer. Pour vous accompagner, voici notre guide d'installation étape par étape.
    image:
      alt: Produits Ween
    install_guide:
      title: Notice d'utilisation
      link: /pdf/notice_ween_fr-1.3.50.pdf
    compatibility_configurator:
      title: Confirmer ma compatibilité
</i18n>
<!-- eslint-enable max-len -->

<template>
  <section class="how-to-install">
    <div
      :class="[
        'display-none--xxs',
        'display-block--m',
        'how-to-install__bg',
        'color-white-smoke',
      ]"
    >
      <icon name="icon-circle" />
    </div>
    <div class="grid">
      <div class="grid__row">
        <div
          :class="[
            'grid__col-12--xxs',
            'grid__col-8--xs',
            'grid__push-2--xs',
            'grid__col-7--m',
            'grid__push-0--m',
            'space-mb-2--m',
            $mq.isOneOf('xxs xs') ? 'align-center justify-center type-align-center' : ''
          ]"
        >
          <h2
            v-if="$mq.isOneOf('xxs xs')"
            class="type-title-secondary type-weight-700"
            v-html="$t('title')"
          />
          <picture class="display-block how-to-install__img">
            <source media="(min-width: 360px)" srcset="/img/product-how-to-install.png" />
            <img src="/img/product-how-to-install--mobile.png" :alt="$t('image.alt')" />
          </picture>
        </div>
        <div class="grid__col-12--xxs grid__col-4--m space-pt-5--m">
          <div class="grid grid--nested">
            <div
              class="grid__row grid__row--center"
              :class="$mq.isOneOf('xxs xs') ? 'align-center justify-center type-align-center' : ''"
            >
              <div class="grid__col-12--xxs grid__col-8--s grid__col-12--m">
                <!-- eslint-disable vue/no-v-html -->
                <h2
                  v-if="!$mq.isOneOf('xxs xs')"
                  class="type-title-secondary type-weight-700"
                  v-html="$t('title')"
                />
                <div class="space-mt-3--xxs space-mt-6--m">
                  <p v-html="$t('content')" />
                </div>
                <!-- eslint-enable vue/no-v-html -->
              </div>
              <div
                :class="[
                  'grid__col-8--xs',
                  'grid__col-6--s',
                  'grid__col-10--m',
                ]"
              >
                <nuxt-link
                  :to="$t('install_guide.link')"
                  target="_blank"
                  :class="[
                    'flex',
                    'align-center',
                    'space-mt-3--xxs',
                    'space-mt-6--m',
                    'display-inline-block',
                    'btn',
                    'btn--secondary',
                    $mq.isOneOf('xxs xs') ? 'btn--fade' : ''
                  ]"
                >
                  <!-- @todo: Put the real icon component -->
                  <icon name="icon-notice-btn" width="22px" height="22px" />
                  <span>{{ $t('install_guide.title') }}</span>
                </nuxt-link>

                <nuxt-link
                  :to="localePath('compatibility')"
                  :class="[
                    'space-mt-3--xxs',
                    'space-mt-4--m',
                    'display-inline-block',
                    'btn',
                    'btn--primary',
                  ]"
                >
                  <span>{{ $t('compatibility_configurator.title') }}</span>
                </nuxt-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Icon from '~/components/Icon';

export default {
  components: {
    Icon,
  },
};
</script>

<style lang="scss">
.how-to-install__img {
  width: 107%;
  max-width: none;

  @media #{md('xs')} {
    width: auto;
    margin: 0;
  }
}

.how-to-install__bg {
  z-index: layer('under-default');
  position: absolute;
  top: 50%;
  left: 40%;
  pointer-events: none;
  transform: translateY(-50%);

  svg {
    width: 90vw;
    height: 90vw;
  }
}

.btn--fade {
  background-color: #eafde6;
}
</style>
