<i18n lang="yaml">
  en:
    title: <span class="color-green">Start saving</span> money and energy
    product: Ween Pack
    features:
      - Ween thermostat and relay
      - Free delivery
      - 2 year warranty
      - Available technical support
      - Cloud hosted in France
    buy-redirect: In order to complete your order, you will be redirected to our payment module website.
    continue: Continue
  fr:
    title: Commencez à <span class="color-green">faire des économies</span>
    product: Pack Ween
    features:
      - Thermostat et relais Ween
      - Livraison offerte
      - Garantie 2 ans
      - Support technique disponible
      - Cloud hébergé en France
    buy-redirect: Afin de finaliser votre commande, vous allez être redirigé vers le site de notre module de paiement.
    continue: Continuer
</i18n>

<template>
  <div :class="$mq.isOneOf('xxs xs s') ? 'space-minus' : ''">
    <div class="type-align-center">
      <h2
        :class="[$mq.isOneOf('xxs xs s') ? 'type-title-secondary' : 'type-title-primary', 'type-weight-700']"
        v-html="$t('title')"
      />
    </div>

    <div class="space-mt-4 grid grid-nested">
      <div class="products grid__row grid__row--center justify-center">
        <div
          v-for="product in products"
          :key="product.id"
          :class="['products__item', 'grid__col-12--xxs', 'grid__col-4--m']"
        >
          <home-product v-bind="product" @click="openModal" />
        </div>
      </div>
    </div>

    <modal name="buy-modal"
      height="auto"
      :adaptive="true"
      @before-open="beforeOpenModal">
      <div class="grid">
        <div class="grid__row">
          <div class="grid__col-10--xxs grid__push-1--xxs space-py-3--xxs space-py-4--m">
            {{ $t('buy-redirect') }}
          </div>
        </div>
        <div class="grid__row">
          <div class="grid__col-10--xxs grid__push-1--xxs">
          <div
            class="space-pb-3--xxs space-pb-4--m flex justify-center align-center"
          >
            <button class="btn btn--primary"
            @click="continueCheckout(productId)"
            >
              {{ $t('continue') }}
            </button>
          </div>
        </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Vue from 'vue';
import VueObserveVisibility from 'vue-observe-visibility';

import VueToggle from '~/components/Toggle';
import Icon from '~/components/Icon';

import HomeProduct from '~/components/Home/Product';

Vue.use(VueObserveVisibility);

export default {
  components: {
    VueToggle,
    Icon,
    HomeProduct,
  },

  data() {
    return {
      products: [
        {
          id: 1,
          title: this.$t('product'),
          reference: 'essential_pack',
          priceMin: 159,
          priceMax: 169,
          features: Object.values(this.$t('features')),
          image: {
            src: '/img/product-essential-thumbnail.png',
            title: this.$t('product'),
          },
        },
      ],
    };
  },

  computed: {
    productTitles() {
      return this.products.map(({ title, reference, price }) => {
        const numbers = `${price} €`;
        return `${title.toUpperCase()}<br /> <h2 class='color-green type-title-secondary'>${numbers}</h2>`;
      });
    },
  },

  methods: {
    openModal(productId) {
      this.$modal.show('buy-modal', { productId: productId });
    },

    beforeOpenModal(event) {
      this.productId = event.params.productId;
    },

    async continueCheckout(productId) {
      const res = await this.$store.$axios.get(
        `${process.env.STRIPE_URL}`,
        { params: { product: productId } }
      );

      const stripe = await this.$store.$stripe;
      await stripe.redirectToCheckout({ sessionId: res.data.id });
    },
  },
};
</script>

<style lang="scss">
.products__item {
  margin-top: 0;

  &:nth-child(n + 3) {
    @media #{md('s')} {
      margin-top: space(10);
    }

    @media #{md('m')} {
      margin-top: 0;
    }
  }

  &:nth-child(n + 4) {
    @media #{md('m')} {
      margin-top: space(10);
    }
  }
}

.home-hero__mark {
  font-size: 1.1em !important;
}

.icon-text {
  display: flex !important;
  align-items: center;
  justify-content: center;

  svg {
    height: 42px !important;
    width: 42px !important;
    margin: 0px 5px 0px 30px;
  }
}

.space-minus {
  margin-top: -60px;
}
</style>
