<template>
  <div class="grid grid__nested">
    <div class="grid__row">
      <div class="grid__col-12--xxs">
        <!-- eslint-disable vue/no-v-html -->
        <h2
          class="type-align-center type-title-secondary type-weight-700"
          v-html="$t('title')"
        />
        <!-- eslint-enable vue/no-v-html -->
      </div>
    </div>
    <div class="grid__row">
      <div class="grid__col-12--xxs grid__col-6--m grid__push-3--m space-mt-15--m">
        <div
          class="ecosystem || space-mt-3--xxs space-mt-6--m"
          :class="{
            'ecosystem--desktop': !isMobileDisplayed
          }"
        >
          <div class="ecosystem__wrapper">
            <nav v-if="isMobileDisplayed === false" class="ecosystem__nav">
              <div
                v-for="item in items"
                :key="`ecosystem__toggle-${item}`"
                class="ecosystem__toggle flex align-center"
                :class="{
                  'ecosystem__toggle--active': currentIndex === null
                    || item === currentIndex,
                  'ecosystem__toggle--exact-active': item === currentIndex
                }"
              >
                <div @click="updateCurrentIndex(item)">
                  <div>
                    <img :src="$t(`items.${item}.image`)" :alt="$t(`items.${item}.title`)" />
                  </div>
                  <vue-icon
                    name="icon-plus"
                    :circle="item !== currentIndex ? 'green' : 'blue'"
                    :opacity="false"
                    class="color-white"
                  />
                </div>
                <!-- eslint-disable vue/no-v-html -->
                <span
                  class="flex-shrink-0 type-title-tertiary type-weight-700"
                  v-html="$t(`items.${item}.title`)"
                />
                <!-- eslint-enable vue/no-v-html -->
              </div>
            </nav>

            <div
              :class="[
                'ecosystem__content',
                'flex',
                'flex-column',
                'justify-center'
              ]"
            >
              <!-- Start: Mobile slider -->
              <div class="home-ecosystem__slider">
                <div class="page-center">
                  <vue-slider
                    v-if="isMobileDisplayed && itemsContentDisplayed.length > 0"
                    class="space-mt-5--xxs"
                  >
                    <slot>
                      <div
                        v-for="item in itemsContentDisplayed"
                        :key="item"
                        class="ecosystem__item type-align-center"
                        style="width: 100%;"
                      >
                        <div>
                          <div>
                            <img :src="$t(`items.${item}.image`)" :alt="$t(`items.${item}.title`)" />
                          </div>
                        </div>

                        <!-- eslint-disable vue/no-v-html -->
                        <h3
                          class="type-title-tertiary type-weight-700 space-mb-3"
                          v-html="$t(`items.${item}.title`)"
                        />
                        <p class="type-body type-weight-300" v-html="$t(`items.${item}.content`)" />
                        <!-- eslint-enable vue/no-v-html -->
                        <div v-if="isAppElement" class="ecosystem-app || space-pt-3">
                          <a
                            v-for="(link, index) in appDownloadLinks"
                            :key="index"
                            :href="$t(link.url)"
                            :title="$t(link.name)"
                            rel="noopener"
                            target="_blank"
                          >
                            <span class="display-hidden-accessible">{{ $t(link.label) }}</span>
                            <vue-icon class="space-mr-2 || display-block" :name="$t(link.logo)" />
                          </a>
                        </div>
                      </div>
                    </slot>
                  </vue-slider>
                </div>
              </div>
              <!-- End: Mobile slider -->

              <!-- Start: Desktop tabs -->
              <!-- eslint-disable max-len -->
              <ul v-if="isMobileDisplayed === false && itemsContentDisplayed.length > 0">
                <!-- eslint-enable max-len -->
                <li
                  v-for="item in itemsContentDisplayed"
                  :key="item"
                  class="ecosystem__item is-selected"
                >
                  <!-- eslint-disable vue/no-v-html -->
                  <h3
                    class="type-title-tertiary type-weight-700 space-mb-3"
                    v-html="$t(`items.${item}.title`)"
                  />
                  <p class="type-body type-weight-300" v-html="$t(`items.${item}.content`)" />
                  <!-- eslint-enable vue/no-v-html -->
                  <div v-if="isAppElement" class="ecosystem-app space-pt-3">
                    <a
                      v-for="(link, index) in appDownloadLinks"
                      :key="index"
                      :href="$t(link.url)"
                      :title="$t(link.name)"
                      rel="noopener"
                      target="_blank"
                    >
                      <span class="display-hidden-accessible">{{ $t(link.label) }}</span>
                      <vue-icon class="space-mr-2 || display-block" :name="$t(link.logo)" />
                    </a>
                  </div>
                </li>
              </ul>
              <!-- End: Desktop tabs -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { appDownloadLinks } from '~/config';
import VueIcon from '~/components/Icon';
import VueSlider from '~/components/Slider';

export default {
  components: {
    VueIcon,
    VueSlider,
  },
  props: {
    /**
     * Current item index
     * @type Number
     */
    current: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      currentIndex: this.current,
      appDownloadLinks,
    };
  },
  computed: {
    items() {
      return [0, 1, 2];
    },
    isMobileDisplayed() {
      return this.$mq.isOneOf('xxs xs s') === true;
    },
    itemsContentDisplayed() {
      return this.isMobileDisplayed
        ? this.items
        : this.items.filter(item => item === this.currentIndex);
    },
    isAppElement() {
      return this.currentIndex === 0;
    },
  },
  methods: {
    updateCurrentIndex(index) {
      if (index === this.currentIndex) {
        this.currentIndex = null;
        return true;
      }

      this.currentIndex = index;
      return true;
    },
  },
};
</script>

<style lang="scss">
$imagePadding--xl: space(4);
$imageContainerWidth--xxs: space(6) + space(10);
$imageContainerWidth--m: space(4) + (space(10) * 2);
$imageContainerWidth--xl: space(4) + (space(10) * 2);

.home-ecosystem__slider {
  left: -#{math.div($mobile-wrapper-space , 2)};
  width: 100vw;
  overflow: hidden;
}

.ecosystem__item {
  opacity: 0;
  transition: opacity 0.4s ease;

  > div {
    width: $imageContainerWidth--xxs;
    margin: 0 auto;
    background-color: color('white');

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: calc(100% - #{$imagePadding--xl * 2});
      max-height: calc(100% - #{$imagePadding--xl * 2});
      transform: translate(-50%, -50%);
    }
  }

  &.is-selected {
    opacity: 1;
  }
}

.ecosystem__nav {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.ecosystem__toggle {
  z-index: layer('above-default');
  position: absolute;
  cursor: pointer;

  > div {
    > div {
      width: $imageContainerWidth--xl;
      height: 0;
      padding-top: 100%;
      background-color: color('white');
      border-radius: 50%;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: calc(100% - #{$imagePadding--xl * 2});
      max-height: calc(100% - #{$imagePadding--xl * 2});
      opacity: 0.3;
      transform: translate(-50%, -50%);
      transition: opacity 0.2s ease;
    }

    > .icon {
      position: absolute;
      right: $imagePadding--xl;
      bottom: $imagePadding--xl;
      transform: rotate(0.1deg);
      transition: transform 0.4s $out-expo;
    }
  }

  > span {
    opacity: 0.3;
    transition: opacity 0.2s ease;
  }

  &.ecosystem__toggle--active,
  &:hover,
  &:focus {
    > span,
    img {
      opacity: 1;
    }
  }

  &.ecosystem__toggle--exact-active {
    .icon {
      transform: rotate(225deg);
    }
  }

  &:nth-child(1) {
    top: 57%;
    right: 77%;
    flex-direction: row-reverse;

    img {
      max-height: 100%;
    }
  }

  &:nth-child(2) {
    bottom: 80%;
    left: 50%;
    align-items: flex-start;
    transform: translate3d(-#{math.div($imageContainerWidth--xl, 2)}, 0, 0);

    img {
      top: auto;
      bottom: $imagePadding--xl;
      max-height: none;
      transform: translate(-50%, 0);
    }
  }

  &:nth-child(3) {
    top: 57%;
    left: 76%;
  }
}

.ecosystem__content {
  .slider::before {
    content: '';
    z-index: layer('under-default');
    position: absolute;
    // top = 60% of image height.
    top: $imageContainerWidth--xxs * 0.6;
    right: -20%;
    left: -20%;
    display: block;
    padding-top: 100%;
    border: 1px solid color('green');
    border-right-width: 0;
    border-bottom-width: 0;
    border-left-width: 0;
    border-radius: 50%;
  }
}

.ecosystem__item > div > div {
  height: 0;
  padding-top: 100%;
}

.ecosystem__item:nth-child(1),
.ecosystem__item:nth-child(2) {
  // @todo: Change this hardcoded value;
  padding-top: 2rem;

  img {
    top: auto;
    bottom: $imagePadding--xl;
    max-height: none;
    transform: translate(-50%, 0);
  }
}

.ecosystem-app {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100% !important;
}

.ecosystem--desktop {
  border: 1px solid color('green');
  border-radius: 50%;

  .ecosystem__wrapper {
    height: 0;
    padding-top: 100%;
  }

  .ecosystem__content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 52%;
    transform: translatex(-50%);
  }

  .ecosystem__item > div {
    width: auto;
    margin: auto;
  }
}
</style>

<!-- eslint-disable max-len -->
<i18n lang="yaml">
  en:
    title: <span class="color-green">Discover</span> the magic world of Ween
    items:
      0:
        title: App
        content:
          Your thermostat and the App communicate and work together to best adapt your home temperature. Via the App, you can adjust in real-time your settings and check your savings.
        image: /img/home-ecosystem/ecosystem-app.png
      1:
        title: <span class="display-block--m">Smart</span> thermostat
        content:
          The hardware optimises and allows you to customise the temperature of your home, based on your location and daily routine. Delivering a <strong>tailored hearing experience</strong>.
        image: /img/home-ecosystem/ecosystem-thermostat.png
      2:
        title: Relay
        content:
          The relay is aptly as it connects any type of heating system to the Ween thermostat. <strong>All done wirelessly</strong>, of course.
        image: /img/home-ecosystem/ecosystem-relay.png
  fr:
    title: <span class="color-green">Découvrir</span> l'univers Ween
    items:
      0:
        title: L'App
        content:
          Votre thermostat et l'App communiquent pour vous offrir une température idéale. L'App vous permet d'ajouter en temps réel vos paramètres et de visualiser vos économies.
        image: /img/home-ecosystem/ecosystem-app.png
      1:
        title: <span class="display-block--m">Le thermostat</span> intelligent
        content:
          Optimise et personnalise pour vous la température de votre maison en fonction de votre éloignement et de vos habitudes, vous procurant un <strong>chauffage sur-mesure</strong>.
        image: /img/home-ecosystem/ecosystem-thermostat.png
      2:
        title: Le relais
        content:
          Il porte bien son nom ! Le relais permet de connecter tout type de chauffage au thermostat Ween. Aucun branchement requis, ces deux là communiquent <strong>par radio</strong>.
        image: /img/home-ecosystem/ecosystem-relay.png
</i18n>
<!-- eslint-enable max-len -->
