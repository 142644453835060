<i18n lang="yaml">
  en:
    meta:
      title: Ween | Save without thinking about it and take action
        for the planet
      description: The Ween thermostat is a personal assistant that adjusts
        the temperature of your home alone to maximize your
        economies and act for the planet.
  fr:
    meta:
      title: Ween | Économisez sur votre chauffage, sans compromis
      description: Le thermostat Ween est un assistant personnel qui ajuste
        tout seul la température de votre logement afin de maximiser vos
        économies et agir pour la planète.
</i18n>

<template>
  <div>
    <vue-hero />
    <vue-section>
      <vue-partners v-if="$mq.isOneOf('m l xl xxl')" />
      <vue-partners-mobile v-else />
    </vue-section>
    <vue-section id="how-to">
      <vue-presentation />
    </vue-section>
    <vue-section id="review" class="layer-above-default">
      <vue-reviews />
    </vue-section>
    <vue-section id="offers">
      <home-products />
    </vue-section>
    <vue-section>
      <vue-ecosystem />
    </vue-section>
    <vue-section>
      <how-to-install />
    </vue-section>
    <nuxt-child />
  </div>
</template>

<script>
import scrollTo from '~/utils/scroll-to';
import HomeProducts from '~/components/Home/Products';
import HowToInstall from '~/components/Home/HowToInstall';
import VueEcosystem from '~/components/Home/Ecosystem';
import VuePresentation from '~/components/Home/Presentation';
import PushCompatibility from '~/components/Home/PushCompatibility';
import VueHero from '~/components/Home/Hero';
import VuePartners from '~/components/Home/Partners';
import VuePartnersMobile from '~/components/Home/PartnersMobile';
import VueReviews from '~/components/Home/Reviews';
import VueSection from '~/components/Section';

export default {
  components: {
    HomeProducts,
    HowToInstall,
    VueEcosystem,
    VuePresentation,
    PushCompatibility,
    VueHero,
    VuePartners,
    VuePartnersMobile,
    VueReviews,
    VueSection,
  },

  head() {
    return {
      title: this.$t('meta.title'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('meta.description'),
        },
      ],
    };
  },

  watch: {
    '$route.hash': async function watchRouteHash(newValue) {
      if (newValue === '')
        return;

      const el = document.querySelector(newValue);
      if (!el)
        return;

      window.location.hash = '';
      await scrollTo(newValue);
    },
  },
};
</script>
