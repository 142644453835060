<!-- eslint-disable max-len -->
<i18n lang="yaml">
  en:
    title:
      inline1: <span class="color-green">How</span> it works?
      inline2: The Ween thermostat <strong>revolutionises programming your heating</strong> based your daily routine and location.
    baseline: It detects when you leave and anticipates your return to <strong>adjust the temperature perfectly</strong>.<br />All without any input from you. <strong><span class="color-green">Magic!</span></strong>
    text: Take your smartphone. <span class="color-green">Ween will handle the rest.</span>
    blocks:
      - title: Optimum comfort - <span class="color-green">always</span>
        content: There’s nothing worse than coming back to a freezing home which is often the case with other thermostats. Our technology prevents that and <strong>ensures the temperature is just right for you from the moment you step through the door</strong>.
      - title: Real-time <span class="color-green">info</span>
        content: Check at <strong>any time</strong> what you’re saving and how the Ween thermostat is working for you
      - title: Easy control - <span class="color-green">anywhere</span>
        content: <strong>Wherever you are</strong>, you can still control the temperature if you want to make any changes, access data or just simply modify the settings.
      - title: Works with <span class="color-green">all energy types</strong>
        content: |
          Don’t you worry, the Ween thermostat is designed to work with <strong>all heating systems</strong>: gas, electricity and fuel, and wood or heat pumps and even peletts.

  fr:
    title:
      inline1: <span class="color-green">Comment</span> ça marche ?
      inline2: Le thermostat Ween <strong>révolutionne la programmation du chauffage</strong> en prenant en compte vos habitudes et votre position.

    baseline: Il <strong>analyse tout seul</strong> le meilleur moment pour modifier la température. <strong><span class="color-green">Magique !</span</strong>
    text: Prenez votre smartphone. <span class="color-green">Ween s’occupe du reste.</span>
    blocks:
      - title: Confort <span class="color-green">toujours optimum</span>
        content: Il n'y a rien de plus désagréable que de rentrer chez soi et d'avoir encore froid, ce qui arrive souvent avec les autres thermostats. Notre technologie garantit <strong>une température toujours adaptée</strong> à vos besoins, dès que vous passez la porte d'entrée.
      - title: Informations <span class="color-green">en<br /> temps réel</span>
        content: Visualisez <strong>à tout moment</strong> ce que vous économisez et comment le thermostat Ween agit pour vous.
      - title: Pilotage à distance <span class="color-green">simplissime</span>
        content: <strong>Où que vous soyez</strong>, vous pouvez toujours contrôler la température de votre logement, visualiser vos économies ou simplement changer des paramètres.
      - title: Fonctionne avec <span class="color-green">tout type d'énergie</span>
        content: |
          Pas d'inquiétude ! Le thermostat Ween est conçu pour fonctionner sur la <strong>totalité des systèmes de chauffage</strong> : gaz, électrique et aussi fioul, bois ou pompes à chaleur et même poêles à pellet.
</i18n>
<!-- eslint-enable max-len -->

<template>
  <div class="space-minus">
    <div
      :class="[
        'display-none--xxs',
        'display-block--s',
        'home-presentation__bg',
        'color-white-smoke',
      ]"
    >
      <icon name="icon-circle" />
    </div>

    <div class="grid__row grid__row--end">
      <div v-if="$mq.isOneOf('xxs xs')" class="grid__col-12--xxs grid__col-5--s">
        <img
          :src="`/img/product-hero-${productImageIndex}-${lang}.png`"
          :alt="$t('product-name')"
          class="display-block space-mx-auto product-hero-img"
          @click="updateImage"
          width="86%"
        />
      </div>
    </div>

    <h2
      class="type-title-secondary type-weight-700 type-align-center"
      v-html="$t('title.inline1')"
    />
    <div
      class="space-mt-4 || type-align-center"
      v-html="$t('title.inline2')"
    />
    <div
      class="space-mb-1 || type-align-center"
      v-html="$t('baseline')"
    />

    <img
      v-if="$mq.isOneOf('xxs xs')"
      src="/img/ween-how-it-works-mobile.svg"
      width="86%"
      class="space-p-6 img-center"
    />
    <img v-else src="/img/ween-how-it-works.svg" alt="how it works" class="space-p-6" />

    <div
      v-if="!$mq.isOneOf('xxs xs')"
      class="type-title-secondary space-mt-10 type-align-center"
      v-html="$t('text')"
    />

    <div v-if="$mq.isOneOf('xxs xs')">
      <div class="page-center space-mt-6">
        <div
          v-for="(block, index) in blocks"
          :key="`home-presentation-slider-${index}`"
          class="grid__col-12--xxs col-push-1 space-mb-4--xxs justify-center type-align-center"
        >
          <h2
            class="type-title-secondary type-weight-700 space-mb-4--xxs"
            v-html="$t(`blocks.${index}.title`)"
          />
          <img
            :src="`${block.img.src}.svg`"
            :alt="`blocks.${index}.title`"
            class="card img-center"
            width="86%"
          />
          <!-- eslint-disable vue/no-v-html -->
          <p class="space-mt-3--xxs space-mt-5--s" v-html="$t(`blocks.${index}.content`)" />
          <!-- eslint-enable vue/no-v-html -->
        </div>
      </div>
    </div>
    <div v-else class="grid__row || flex">
      <div
        :class="[
          'grid__col-12--xxs',
          'grid__col-5--s',
          'grid__col-4--m',
          'grid__push-1--l',
          'space-py-13',
        ]"
      >
        <div
          class="home-presentation__sticky"
          :style="{
            top: blocks.length
              ? `calc(50vh - ${blocks[0].img.height / 2}px)`
              : null,
          }"
        >
          <img
            v-for="(block, index) in blocks"
            :key="`home-presentation-img-${index}`"
            ref="imgs"
            :alt="`blocks.${index}.title`"
            :src="`${block.img.src}.svg`"
            @mouseenter="toggleImage('enter')"
            @mouseout="toggleImage('out')"
            :data-block="index"
            :class="[
              'home-presentation__img',
              'card',
              0 == index ? 'is-show' : '',
            ]"
            @load="handleLoadImg"
          />
        </div>
      </div>
      <div
        :class="[
          'grid__col-12--xxs',
          'grid__col-6--s',
          'grid__col-5--m',
          'grid__push-1--m',
          'grid__col-4--l',
        ]"
      >
        <div
          v-for="(block, index) in blocks"
          :key="`home-presentation-block-${index}`"
          class="space-py-13"
        >
          <div
            ref="blocks"
            class="home-presentation__block"
            :style="`min-height: ${block.img.height}px;`"
            :data-block="index"
          >
            <h2 class="type-title-secondary type-weight-700" v-html="$t(`blocks.${index}.title`)" />
            <!-- eslint-disable vue/no-v-html -->
            <p class="space-mt-3--xxs space-mt-5--s" v-html="$t(`blocks.${index}.content`)" />
            <!-- eslint-enable vue/no-v-html -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '~/components/Icon';

export default {
  components: {
    Icon,
  },
  data() {
    return {
      carouselImages: null,
      productImageIndex: 0,
      lang: this.$i18n.locale === 'fr' ? 'fr' : 'en',
      scrollImgSrc: '',
      blocks: [
        {
          img: {
            src: '/img/ween-comfort',
            width: 0,
            height: 0,
          },
        },
        {
          img: {
            src: '/img/ween-speed',
            width: 0,
            height: 0,
          },
        },
        {
          img: {
            src: '/img/ween-remote',
            width: 0,
            height: 0,
          },
        },
        {
          img: {
            src: '/img/ween-compatibility',
            width: 0,
            height: 0,
          },
        },
      ],
    };
  },
  created() {
    this.carouselImages = setInterval(() => {
      this.updateImage();
    }, 3000);
  },
  destroyed() {
    clearInterval(this.carouselImages);
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, { passive: true });
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    updateImage() {
      this.productImageIndex = (this.productImageIndex + 1) % 3; // 3 is the total nb of images
    },
    sorter(el1, el2) {
      const el1Size = el1.getBoundingClientRect();
      const el2Size = el2.getBoundingClientRect();
      /* eslint-disable */
      const pos1 = Math.abs(
        el1Size.top + el1Size.height / 2 - window.innerHeight / 2
      );
      const pos2 = Math.abs(
        el2Size.top + el2Size.height / 2 - window.innerHeight / 2
      );
      /* eslint-enable */
      return pos1 - pos2;
    },
    updateBlockMinHeight(indexBlock, minHeight) {
      this.$refs.blocks.forEach(block => {
        if (indexBlock === block.dataset.block) {
          this.blocks[block.dataset.block].img.height = minHeight;
        }
      });
    },
    handleLoadImg(event) {
      const { height } = event.target.getBoundingClientRect();
      this.updateBlockMinHeight(event.target.dataset.block, height);
    },
    handleScroll() {
      let index = null;

      if (!this.$refs.blocks.length) {
        return false;
      }

      this.$refs.blocks.sort(this.sorter);

      index = this.$refs.blocks[0].dataset.block;

      this.$refs.imgs.forEach(img => {
        img.classList.remove('is-show');
      });

      this.$refs.imgs[index].classList.add('is-show');

      return true;
    },
    handleResize() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.$refs.imgs.forEach(img => {
          const { height } = img.getBoundingClientRect();
          this.updateBlockMinHeight(img.dataset.block, height);
        });
      }, 300);
    },
    toggleImage(name) {
      const index = this.$refs.blocks[0].dataset.block;
      this.$refs.imgs[index].setAttribute(
        'src',
        `${this.blocks[index].img.src}${name === 'enter' ? '-hover' : ''}.svg`
      );
    },
  },
};
</script>

<style lang="scss">
.space-minus {
  margin-top: -60px;
}

.home-presentation__bg {
  z-index: -1;
  position: absolute;
  top: 50%;
  right: math.div((5 * 100), $grid-columns) * 1%;
  pointer-events: none;
  transform: translateY(-50%);

  svg {
    width: 100vw;
    height: 100vw;
  }
}

.img-center {
  margin: auto;
}

.home-presentation__slider {
  left: -#{math.div($mobile-wrapper-space, 2)};
  width: 100vw;
  overflow: hidden;
}

.home-presentation__sticky {
  position: sticky;
  top: calc(50vh - 25%);
}

.home-presentation__img {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.2s;

  &.is-show {
    position: relative;
    opacity: 1;
  }
}

.home-presentation__block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* stylelint-disable */
  transition: min-height 0.2s;
  /* stylelint-enable */

  b {
    font-weight: 700;
  }
}

.home-presentation__surtitle {
  display: inline-block;
  width: 32px;
  height: 32px;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  border-radius: 50%;
}

.wizzard {
  width: 42px;
}
</style>
