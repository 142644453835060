<i18n lang="yaml">
  en:
    cta: Buy
    cta-unavailable: Unavailable
    from: from
    price-min-desc: <strong>Save {savings} €</strong> choosing to receive your Ween Pack
      with a reduced and low carbon footprint packaging.
    price-max-desc: Or receive your Ween Pack in its classic packaging.
  fr:
    cta: Acheter
    cta-unavailable: Indisponible
    from: à partir de
    price-min-desc: <strong>Économisez {savings} €</strong> en choisissant de recevoir votre pack Ween
      avec un packaging réduit à faible empreinte carbone.
    price-max-desc: Ou recevez votre pack Ween dans son packaging classique.
</i18n>

/* eslint-disable camelcase */
<template>
  <article
    itemscope
    itemtype="http://schema.org/Product"
    :name="title"
    :class="[
      'product',
      'product--card',
      'card',
      'background-white',
      'space-px-3--xxs',
      'space-px-6--m',
      'space-pb-6--xxs',
      'space-pb-7--m',
      'space-mt-10--xxs',
      'space-mt-15--m',
    ]"
  >
    <header class="type-align-center">
      <img
        :src="image.src"
        :alt="image.title"
        :width="$mq.isOneOf('m l xl xxl') ? 238 : 169"
        class="product__image"
        itemprop="image"
      />

      <h3
        itemprop="name"
        :class="[
          'space-pt-10--xxs',
          'space-pt-15--m',
          'type-title-secondary',
          'type-weight-700',
        ]"
      >{{ !$mq.isOneOf('xxs xs') ? title : '' }}</h3>

      <div>
        <span
          itemprop="price"
          :content="priceMin"
          class="display-block type-price type-weight-700 space-mt-2 new-price"
        >{{ $t('from') }} {{ priceMin }} €</span>
      </div>
    </header>

    <ul class="checklist">
      <li
        v-for="feature in features"
        :key="feature"
        :class="[ 'checklist__item', 'flex align-center', 'space-py-2' ]"
      >
        <icon
          name="icon-checked"
          class="color-green flex-shrink-0"
          width="18px"
          height="18px"
        />

        <span
          :class="[
          'space-ml-2--xxs',
          'space-ml-3--s',
          'type-weight-300',
          'flex-grow-1',
        ]"
        >{{ feature }}</span>
      </li>
    </ul>

    <footer
      class="space-mt-2 type-align-center"
      itemprop="offers"
      itemscope
      itemtype="http://schema.org/Offer"
    >
      <span
        class="type-weight-300 display-block"
        v-html="$t('price-min-desc', { savings: (priceMax - priceMin) })"
      >
      </span>

      <button
        class="space-mt-1 btn btn--primary"
        @click="$emit('click', `${reference}_min`)"
      >
        {{ $t('cta') }} {{ priceMin }} €
      </button>

      <span class="type-weight-300 space-mt-3 display-block">
        {{ $t('price-max-desc') }}
      </span>

      <button
        class="space-mt-1 btn btn--secondary"
        @click="$emit('click', `${reference}_max`)"
      >
        {{ $t('cta') }} {{ priceMax }} €
      </button>
    </footer>
  </article>
</template>

<script>
import Icon from '~/components/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    reference: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
    image: {
      type: Object,
      required: true,
    },
    priceMin: {
      type: Number,
      required: true,
    },
    priceMax: {
      type: Number,
      required: true,
    },
    features: {
      type: Array,
      required: true,
    },
  },

  computed: {
    featuresList() {
      return this.features.filter(
        item => item.title !== '' && !/^[0-9]+$/.test(item.title)
      );
    },
  },
};
</script>

<style lang="scss">
.new-price {
  font-size: 1.2em;
  color: color('green');
}

.product {
  overflow: visible;
  cursor: pointer;

  .product__image {
    position: absolute;
    top: 0;
    left: 50%;
    margin: 0 auto;
    transform: translate(-50%, -60%);
  }
}

$font-size: 16px;
$line-height: 28px;

.checklist__item {
  font-size: math.div($font-size, $font-size-root) * 1rem;
  line-height: math.div($line-height, $font-size) * 1em;

  + .checklist__item {
    border-top: 1px solid color('grey');
  }
}
</style>
