<template>
  <div class="toggle">
    <slot name="beforeToggle" :current="current" />
    <div class="flex flex-row toggle__controls space-mb-5 space-mb-10--m">
      <div
        class="toggle__active-background"
        :style="{
          width: `${width}px`,
          transform: `translate3d( ${left}px, 0, 0)`}"
      />
      <div
        v-for="(label, index) in labels"
        :key="`${index}-${label}`"
        ref="labels"
        class="toggle__label"
        :class="index === current ? 'toggle__label--active' : ''"
        @click="clickHandler(index)"
        v-html="label"
      ></div>
    </div>
    <div>
      <slot :current="current">{{ current }}</slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'VueToggle',
  props: {
    labels: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      current: 0,
      left: 0,
      width: 0,
    };
  },
  mounted() {
    this.setSizes();
    window.addEventListener('resize', this.resizeHandler);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeHandler);
  },
  methods: {
    resizeHandler() {
      clearTimeout(this.timer);
      this.timer = setTimeout(this.setSizes, 300);
    },
    clickHandler(index) {
      this.current = index;
      this.$nextTick(this.setSizes);
    },
    setSizes() {
      const el = this.$refs.labels[this.current];
      this.left = el.offsetLeft;
      this.width = el.offsetWidth;
    },
  },
};
</script>


<style lang="scss">
.toggle__controls {
  position: relative;
  flex-basis: auto;
  width: fit-content;
  margin-right: auto;
  margin-left: auto;
  background: c('grey');
  border: 0;
  border-radius: 100px;
  box-shadow: 0 0 0 3px c('grey');

  @media #{md('m', 'max')} {
    width: 100%;
  }
}

.toggle__label {
  z-index: 1;
  flex: 1;
  padding: 1.9em 1.5em;
  font-family: font-family('sans');
  font-size: 10px;
  font-weight: 700;
  line-height: 2em;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 100px;
  transition: color 0.6s $out-expo;

  @media #{md('m')} {
    @include font-size('btn');
    // min-width: 11em;
    padding: 1.157em 1.5em;
  }
}

.toggle__label--active {
  color: c('grey');
}

.toggle__active-background {
  position: absolute;
  left: 0;
  height: 100%;
  background: c('blue');
  border-radius: 100px;
  box-shadow: $box-shadow;
  // stylelint-disable-next-line
  transition: transform 0.6s $out-expo, width 0.6s $out-expo;
}
</style>
