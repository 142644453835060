/* eslint-disable plugin/no-low-performance-animation-properties */
<i18n lang="yaml">
  en:
    title: <span class="color-green">Press</span> review
  fr:
    title: On parle de Ween dans <span class="color-green">les medias</span>
</i18n>

<template class="space-pb-10--s">
  <div class="grid partners-align">
    <div class="grid__row">
      <div class="grid__col-12--xxs grid__col-12--s">
        <div class="partners">
          <div class="grid grid--nested">
            <div class="grid__row grid__row--center">
              <div
                v-for="(logo, index) in sortedPartnersLogos()"
                :key="`home-partners-${index}`"
                class="space-mb-1 grid__col-3--xxs grid__col-1--s"
              >
                <a :href="logo.link" target="_blank" rel="noopener">
                  <vue-icon :name="`logo-${logo.name}`" width="100%" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid__row grid__row--center justify-center space-mt-8">
      <div class="grid__col-1--xxs grid__col-1--s space-mt-4">
        <a href="#how-to">
          <img src="/img/bunney-nose.svg" alt="mouse scroll down" class="mouse-below space-mt-4" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { partners } from '~/config';
import VueIcon from '~/components/Icon';

export default {
  components: {
    VueIcon,
  },
  data() {
    return {
      logos: partners,
    };
  },
  methods: {
    sortedPartnersLogos() {
      if (this.$i18n.locale === 'fr') {
        this.logos.slice(0, -1);
        return this.logos;
      }

      const [bfm, ...others] = this.logos;
      const [wired, ces] = others.splice(others.length - 2, others.length);

      return [wired, ces, ...others, bfm];
    },
  },
};
</script>

<style lang="scss">
.partners-align {
  margin-top: -160px;
  padding-bottom: 0px !important;
}

.mouse-below {
  width: 21px;
  background-color: #fff !important;
  animation: MoveUpDown 1.1s linear infinite;
}

@keyframes MoveUpDown {
  0%,
  100% {
    bottom: 0;
  }
  50% {
    bottom: 20px;
  }
}
</style>
