import damp from './damp';

/**
 * Handler for the click event on anchor links
 */
export default function scrollTo(selector, offset = 0) {
  // Get the target offset
  const target = document.querySelector(selector);
  const sizes = target.getBoundingClientRect();
  const max = document.body.offsetHeight - window.innerHeight;
  let top = sizes.top + window.pageYOffset + offset;

  // Make sure to not scroll more than the max scroll allowed
  if (top >= max) {
    top = max;
  }

  // Start the scroll
  return new Promise(resolve => {
    /** @type {Boolean} Are we smooth scrolling? */
    let isScrolling = false;

    /** @type {Number} The current value of the damped scroll */
    let dampScroll = window.pageYOffset;

    /** @type {Number} The target value for the damped scroll */
    let scroll = window.pageYOffset;

    /**
     * Handler for the wheel event
     * Is used to cancel the programmatic scroll animation
     */
    function wheelHandler() {
      if (isScrolling) {
        isScrolling = false;
      }
    }

    /**
     * Scroll animation end's hook
     */
    function end() {
      resolve({ x: window.pageXOffset, y: window.pageYOffset });
      window.removeEventListener('wheel', wheelHandler);
    }

    /**
     * Scroll animation's loop
     */
    function loop() {
      if (!isScrolling) {
        return end();
      }

      dampScroll = damp(scroll, dampScroll, 0.05);
      window.scrollTo({ top: dampScroll });

      if (dampScroll === scroll) {
        isScrolling = false;
      }

      return requestAnimationFrame(loop);
    }

    /**
     * Start the scroll animation.
     *
     * @param {Number} targetScroll The target scroll
     */
    function start(targetScroll) {
      // Update vars
      isScrolling = true;
      dampScroll = window.pageYOffset;
      scroll = targetScroll;

      // Bind wheel event to stop the animation if
      // the user wants to scroll manually
      window.addEventListener('wheel', wheelHandler, { passive: true });

      // Init the loop
      loop();
    }

    start(top);
  });
}
