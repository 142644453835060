var render = function render(){var _vm=this,_c=_vm._self._c;return _c('article',{class:[
    'product',
    'product--card',
    'card',
    'background-white',
    'space-px-3--xxs',
    'space-px-6--m',
    'space-pb-6--xxs',
    'space-pb-7--m',
    'space-mt-10--xxs',
    'space-mt-15--m',
  ],attrs:{"itemscope":"","itemtype":"http://schema.org/Product","name":_vm.title}},[_c('header',{staticClass:"type-align-center"},[_c('img',{staticClass:"product__image",attrs:{"src":_vm.image.src,"alt":_vm.image.title,"width":_vm.$mq.isOneOf('m l xl xxl') ? 238 : 169,"itemprop":"image"}}),_vm._v(" "),_c('h3',{class:[
        'space-pt-10--xxs',
        'space-pt-15--m',
        'type-title-secondary',
        'type-weight-700',
      ],attrs:{"itemprop":"name"}},[_vm._v(_vm._s(!_vm.$mq.isOneOf('xxs xs') ? _vm.title : ''))]),_vm._v(" "),_c('div',[_c('span',{staticClass:"display-block type-price type-weight-700 space-mt-2 new-price",attrs:{"itemprop":"price","content":_vm.priceMin}},[_vm._v(_vm._s(_vm.$t('from'))+" "+_vm._s(_vm.priceMin)+" €")])])]),_vm._v(" "),_c('ul',{staticClass:"checklist"},_vm._l((_vm.features),function(feature){return _c('li',{key:feature,class:[ 'checklist__item', 'flex align-center', 'space-py-2' ]},[_c('icon',{staticClass:"color-green flex-shrink-0",attrs:{"name":"icon-checked","width":"18px","height":"18px"}}),_vm._v(" "),_c('span',{class:[
        'space-ml-2--xxs',
        'space-ml-3--s',
        'type-weight-300',
        'flex-grow-1',
      ]},[_vm._v(_vm._s(feature))])],1)}),0),_vm._v(" "),_c('footer',{staticClass:"space-mt-2 type-align-center",attrs:{"itemprop":"offers","itemscope":"","itemtype":"http://schema.org/Offer"}},[_c('span',{staticClass:"type-weight-300 display-block",domProps:{"innerHTML":_vm._s(_vm.$t('price-min-desc', { savings: (_vm.priceMax - _vm.priceMin) }))}}),_vm._v(" "),_c('button',{staticClass:"space-mt-1 btn btn--primary",on:{"click":function($event){return _vm.$emit('click', `${_vm.reference}_min`)}}},[_vm._v("\n      "+_vm._s(_vm.$t('cta'))+" "+_vm._s(_vm.priceMin)+" €\n    ")]),_vm._v(" "),_c('span',{staticClass:"type-weight-300 space-mt-3 display-block"},[_vm._v("\n      "+_vm._s(_vm.$t('price-max-desc'))+"\n    ")]),_vm._v(" "),_c('button',{staticClass:"space-mt-1 btn btn--secondary",on:{"click":function($event){return _vm.$emit('click', `${_vm.reference}_max`)}}},[_vm._v("\n      "+_vm._s(_vm.$t('cta'))+" "+_vm._s(_vm.priceMax)+" €\n    ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }