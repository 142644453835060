<template>
  <div
    v-if="background"
    class="image"
    :style="style" />
  <img
    v-else
    class="image"
    :src="src"
    :alt="alt"
    :width="width"
    :height="height">
</template>

<script>
  export default {
    props: {
      src: {
        type: String,
        required: true,
      },
      alt: {
        type: String,
        default: '',
      },
      width: {
        type: Number,
        default: -1,
      },
      height: {
        type: Number,
        default: -1,
      },
      background: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        isLoaded: false,
      };
    },
    computed: {
      style() {
        if (!this.isLoaded) {
          return {};
        }

        return {
          backgroundImage: `url(${this.src})`,
        };
      },
    },
    mounted() {
      const img = new Image();
      img.onload = () => {
        this.isLoaded = true;
      };
      img.src = this.src;
    },
  };
</script>

<style lang="scss" scoped>
  .image {
    background-color: c('grey');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
  }
</style>
