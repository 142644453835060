var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reviews-section"},[_c('div',{staticClass:"type-align-center"},[_c('h2',{class:[
        'space-mb-7--xxs',
        'space-mb-13--m',
        'type-title-secondary',
        'type-weight-700',
        'type-align-center',
      ],domProps:{"innerHTML":_vm._s(_vm.$t('title'))}})]),_vm._v(" "),(_vm.$mq.isOneOf('xxs s'))?_c('div',{staticClass:"home-reviews__slider space-pt-2--xxs"},[_c('div',{staticClass:"page-center"},[_c('div',{staticClass:"grid__row"},[_c('vue-slider',_vm._l((_vm.reviews),function(review,index){return _c('div',{key:index,staticClass:"grid__col-12--xxs grid__col-4--m flex flex-grow-1"},[_c('div',{staticClass:"review__card card card--overflow-visible background-white space-py-6 space-px-5"},[_c('div',{staticClass:"review__icon"},[_c('div',{staticClass:"grid__row"},[_c('div',{staticClass:"grid__col-4--xxs flex flex-grow-1"},[_c('img',{attrs:{"src":`/img/quote-face-${review.icon}.svg`,"alt":`Review of ${review.user}`}})]),_vm._v(" "),_c('div',{staticClass:"grid__col-8--xxs grid__col-4--m flex flex-grow-1"},[_c('div',{staticClass:"flex space-mt-4"},[_c('p',{class:[ 'space-mr-1',
                                  'space-mb-3',
                                  'type-title-tertiary',
                                  'type-weight-700',
                                  'review__user' ]},[_vm._v(_vm._s(review.user))])])])])]),_vm._v(" "),_c('div',{staticClass:"flex space-mt-4"}),_vm._v(" "),_c('client-only',[_c('star-rating',{staticClass:"space-mb-3",attrs:{"show-rating":false,"increment":0.5,"star-size":27,"active-color":"#FBB829","rating":review.rating,"read-only":""}})],1),_vm._v(" "),_c('p',{staticClass:"comment",domProps:{"innerHTML":_vm._s(review.message)}}),_vm._v(" "),_c('vue-icon',{staticClass:"review__icon-corner",attrs:{"name":"icon-quote"}})],1)])}),0)],1)])]):_c('div',{staticClass:"grid__row grid__row--stretch"},_vm._l((_vm.reviews),function(review,index){return _c('div',{key:index,staticClass:"grid__col-12--xxs grid__col-4--m flex flex-grow-1"},[_c('div',{staticClass:"review__card card card--overflow-visible background-white space-py-6 space-px-5"},[_c('div',{staticClass:"review__icon"},[_c('img',{attrs:{"src":`/img/quote-face-${review.icon}.svg`,"alt":`Review of ${review.user}`}})]),_vm._v(" "),_c('div',{staticClass:"flex space-mt-4"},[_c('p',{class:[
              'space-mr-1',
              'space-mb-3',
              'type-title-tertiary',
              'type-weight-700',
              'review__user',
            ]},[_vm._v(_vm._s(review.user))])]),_vm._v(" "),_c('client-only',[_c('star-rating',{staticClass:"space-mb-3",attrs:{"show-rating":false,"increment":0.5,"star-size":27,"active-color":"#FBB829","rating":review.rating,"read-only":""}})],1),_vm._v(" "),_c('p',{staticClass:"comment",domProps:{"innerHTML":_vm._s(review.message)}}),_vm._v(" "),_c('vue-icon',{staticClass:"review__icon-corner",attrs:{"name":"icon-quote"}})],1)])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }