<template>
  <div
    class="slider"
    :class="classes">
    <div ref="sliderContainer">
      <slot />
    </div>
    <div
      v-if="buttons"
      ref="previous"
      class="btn btn--secondary slider__previous"
      @click="previous">
      <svg-arrow-down />
    </div>
    <div
      v-if="buttons"
      ref="next"
      class="btn btn--secondary slider__next"
      @click="next">
      <svg-arrow-down />
    </div>
  </div>
</template>

<script>
  import SvgArrowDown from '~/assets/svg/icon-arrow-down.svg';

  export default {
    name: 'Slider',

    components: {
      SvgArrowDown,
    },

    props: {
      buttons: {
        type: Boolean,
        default: true,
      },
      pageDots: {
        type: Boolean,
        default: true,
      },
      autoPlay: {
        type: [ Boolean, Number ],
        default: false,
      },
      sliderOptions: {
        type: Object,
        default() {
          return {
            wrapAround: true,
            contain: true,
            prevNextButtons: false,
            pageDots: this.pageDots,
            autoPlay: this.autoPlay,
          };
        },
      },
      verticalCenter: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        slider: null,
      };
    },

    computed: {
      classes() {
        return {
          'slider--no-buttons': !this.buttons,
          'slider--vertical-center': this.verticalCenter,
        };
      },
    },

    async mounted() {
      const module = await import(/* webpackChunkName: "flickity" */'flickity');
      this.Flickity = module.default;

      this.$nextTick(this.initSlider);
    },

    methods: {
      initSlider() {
        this.slider = new this.Flickity(
          this.$refs.sliderContainer,
          this.sliderOptions,
        );
        this.slider.on('change', this.flickityChangeEmit);
      },
      next() {
        this.slider.next();
      },
      previous() {
        this.slider.previous();
      },
      flickityChangeEmit() {
        this.$emit('change');
      },
    },
  };
</script>

<style lang="scss">
  .slider {
    width: 100%;
    padding-bottom: 40px + 56px;
  }

  .slider--no-buttons {
    padding-bottom: 0;
  }

  .slider__previous {
    position: absolute;
    bottom: 0;
    left: 0;
    width: s(7);
    height: s(7);

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }

  .slider__next {
    position: absolute;
    right: 0;
    bottom: 0;
    width: s(7);
    height: s(7);

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-90deg);
    }
  }

  .flickity-viewport,
  .flickity-slider {
    height: 100%;
  }

  .slider--vertical-center .flickity-slider {
    display: flex;
    align-items: center;
  }

  .flickity-viewport {
    position: relative;
    width: 100%;
    padding-bottom: 30px;
  }

  /*! purgecss start ignore */
  .flickity-page-dots {
    position: absolute;
    top: calc(100% + 40px);
    left: 50%;
    display: flex;
    align-items: center;
    height: s(7);
    transform: translateX(-50%);

    .dot {
      display: block;
      width: s(1);
      height: s(1);
      margin: 0 s(1);
      cursor: pointer;
      background: c('blue');
      border-radius: 50%;
      opacity: 0.3;
      transition: opacity 0.2s ease-in-out;

      &.is-selected {
        opacity: 1;
      }
    }
  }
  /*! purgecss end ignore */

  .is-pointer-down {
    cursor: grabbing;
  }
</style>
