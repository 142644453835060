/**
 * Smoothly update a value to reach the given target.
 *
 * @param {Number}  target  The final value to reach
 * @param {Number}  current The current value
 * @param {Number}  speed   The speed of the damping
 * @return {Number}         A damped number corresponding to the current value
 */
export default function damp(target, current, speed = 0.05) {
  const value = current + (target - current) * speed;
  return Math.abs(target - current) < 0.1 ? target : value;
}
