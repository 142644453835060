var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"how-to-install"},[_c('div',{class:[
      'display-none--xxs',
      'display-block--m',
      'how-to-install__bg',
      'color-white-smoke',
    ]},[_c('icon',{attrs:{"name":"icon-circle"}})],1),_vm._v(" "),_c('div',{staticClass:"grid"},[_c('div',{staticClass:"grid__row"},[_c('div',{class:[
          'grid__col-12--xxs',
          'grid__col-8--xs',
          'grid__push-2--xs',
          'grid__col-7--m',
          'grid__push-0--m',
          'space-mb-2--m',
          _vm.$mq.isOneOf('xxs xs') ? 'align-center justify-center type-align-center' : ''
        ]},[(_vm.$mq.isOneOf('xxs xs'))?_c('h2',{staticClass:"type-title-secondary type-weight-700",domProps:{"innerHTML":_vm._s(_vm.$t('title'))}}):_vm._e(),_vm._v(" "),_c('picture',{staticClass:"display-block how-to-install__img"},[_c('source',{attrs:{"media":"(min-width: 360px)","srcset":"/img/product-how-to-install.png"}}),_vm._v(" "),_c('img',{attrs:{"src":"/img/product-how-to-install--mobile.png","alt":_vm.$t('image.alt')}})])]),_vm._v(" "),_c('div',{staticClass:"grid__col-12--xxs grid__col-4--m space-pt-5--m"},[_c('div',{staticClass:"grid grid--nested"},[_c('div',{staticClass:"grid__row grid__row--center",class:_vm.$mq.isOneOf('xxs xs') ? 'align-center justify-center type-align-center' : ''},[_c('div',{staticClass:"grid__col-12--xxs grid__col-8--s grid__col-12--m"},[(!_vm.$mq.isOneOf('xxs xs'))?_c('h2',{staticClass:"type-title-secondary type-weight-700",domProps:{"innerHTML":_vm._s(_vm.$t('title'))}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"space-mt-3--xxs space-mt-6--m"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('content'))}})])]),_vm._v(" "),_c('div',{class:[
                'grid__col-8--xs',
                'grid__col-6--s',
                'grid__col-10--m',
              ]},[_c('nuxt-link',{class:[
                  'flex',
                  'align-center',
                  'space-mt-3--xxs',
                  'space-mt-6--m',
                  'display-inline-block',
                  'btn',
                  'btn--secondary',
                  _vm.$mq.isOneOf('xxs xs') ? 'btn--fade' : ''
                ],attrs:{"to":_vm.$t('install_guide.link'),"target":"_blank"}},[_c('icon',{attrs:{"name":"icon-notice-btn","width":"22px","height":"22px"}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('install_guide.title')))])],1),_vm._v(" "),_c('nuxt-link',{class:[
                  'space-mt-3--xxs',
                  'space-mt-4--m',
                  'display-inline-block',
                  'btn',
                  'btn--primary',
                ],attrs:{"to":_vm.localePath('compatibility')}},[_c('span',[_vm._v(_vm._s(_vm.$t('compatibility_configurator.title')))])])],1)])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }