<i18n lang="yaml">
  en:
    title: <span class="color-green">They already made the decison</span> to not waste anymore energy
    michel:
      message: Nice App, very good thermostat and a responsive support team.
               Since the first month, I can see real energy savings done.
    laura:
      message: An efficient thermostat, a very comprehensive support team and regular updates!
               It is rare to see such service these days.
    jerome:
      message: Efficient and simple App, very satisfying product. Thank you for making our life easier.
  fr:
    title: <span class="color-green">Ils ont déjà choisi</span> de ne plus gaspiller d'énergie
    michel:
      message: Une App sympa, un très bon thermostat et une équipe Ween réactive au moindre problème.
               Dès le premier mois, je constate de vraies économies d'énergie.
    laura:
      message: Un thermostat efficace, un support de très bonne qualité, et des mises à jours régulières !
               C’est rare de voir ça.
    jerome:
      message: App simple et efficace, produit très satisfaisant. Merci pour ce travail qui nous facilite la vie.
</i18n>

<template>
  <div class="reviews-section">
    <div class="type-align-center">
      <h2
        :class="[
          'space-mb-7--xxs',
          'space-mb-13--m',
          'type-title-secondary',
          'type-weight-700',
          'type-align-center',
        ]"
        v-html="$t('title')"
      />
    </div>

    <!-- eslint-enable vue/no-v-html -->
    <div v-if="$mq.isOneOf('xxs s')" class="home-reviews__slider space-pt-2--xxs">
      <div class="page-center">
        <div class="grid__row">
          <vue-slider>
            <div
              v-for="(review, index) in reviews"
              :key="index"
              class="grid__col-12--xxs grid__col-4--m flex flex-grow-1"
            >
              <div
                class="review__card card card--overflow-visible background-white space-py-6 space-px-5"
              >
                <div class="review__icon">
                  <div class="grid__row">
                    <div class="grid__col-4--xxs flex flex-grow-1">
                      <img
                        :src="`/img/quote-face-${review.icon}.svg`"
                        :alt="`Review of ${review.user}`"
                      />
                    </div>
                    <div class="grid__col-8--xxs grid__col-4--m flex flex-grow-1">
                      <div class="flex space-mt-4">
                        <p
                          :class="[ 'space-mr-1',
                                    'space-mb-3',
                                    'type-title-tertiary',
                                    'type-weight-700',
                                    'review__user' ]"
                        >{{ review.user }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex space-mt-4">
                </div>
                <client-only>
                  <star-rating
                    class="space-mb-3"
                    :show-rating="false"
                    :increment="0.5"
                    :star-size="27"
                    active-color="#FBB829"
                    :rating="review.rating"
                    read-only
                  />
                </client-only>
                <p class="comment" v-html="review.message" />
                <vue-icon name="icon-quote" class="review__icon-corner" />
              </div>
            </div>
          </vue-slider>
        </div>
      </div>
    </div>
    <div v-else class="grid__row grid__row--stretch">
      <div
        v-for="(review, index) in reviews"
        :key="index"
        class="grid__col-12--xxs grid__col-4--m flex flex-grow-1"
      >
        <div
          class="review__card card card--overflow-visible background-white space-py-6 space-px-5"
        >
          <div class="review__icon">
            <img :src="`/img/quote-face-${review.icon}.svg`" :alt="`Review of ${review.user}`" />
            <!-- <vue-icon name="icon-quote" /> -->
          </div>
          <div class="flex space-mt-4">
            <p
              :class="[
                'space-mr-1',
                'space-mb-3',
                'type-title-tertiary',
                'type-weight-700',
                'review__user',
              ]"
            >{{ review.user }}</p>
          </div>
          <client-only>
            <star-rating
              class="space-mb-3"
              :show-rating="false"
              :increment="0.5"
              :star-size="27"
              active-color="#FBB829"
              :rating="review.rating"
              read-only
            />
          </client-only>
          <p class="comment" v-html="review.message" />
          <vue-icon name="icon-quote" class="review__icon-corner" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueIcon from '~/components/Icon';
import VueSlider from '~/components/Slider';

export default {
  name: 'Reviews',

  components: {
    VueIcon,
    VueSlider,
    StarRating: () => process.client ? import ('vue-star-rating') : null,
  },

  data() {
    return {
      lang: this.$i18n.locale,
      reviews: [
          {
            user: 'Michel',
            icon: 'man',
            message: this.$t('michel.message'),
            rating: 4.5,
          },
          {
            user: 'Laura',
            icon: 'woman',
            message: this.$t('laura.message'),
            rating: 5,
          },
          {
            user: 'Jérôme',
            icon: 'man-beard',
            message: this.$t('jerome.message'),
            rating: 4.5,
          },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.reviews-section {
  margin-top: -80px;
}

.home-reviews__slider {
  left: -#{math.div($mobile-wrapper-space , 2)};
  width: 100vw;
  overflow: hidden;
}

.review__card {
  height: 100%;
}

.comment {
  font-style: italic;
}

.review__icon {
  position: absolute;
  top: 0;
  margin-top: -16px;

  img {
    width: 86px;
  }
}

.review__icon-corner {
  position: absolute;
  right: 13px;
  bottom: 13px;
}

.anchor {
  text-decoration: none;
}
</style>
