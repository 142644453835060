<i18n lang="yaml">
  en:
    title: <span class="color-green">Press</span> review
  fr:
    title: Revue de <span class="color-green">presse</span>
</i18n>

<template>
  <div class="partners">
    <hr class="space-mt-4 space-mb-6 hr color-grey" />

    <div class="grid__row grid__row--center">
      <div
        v-for="(logo, index) in logos"
        :key="`home-partners-mobile-${index}`"
        :class="index == logos.length - 1 ? 'grid__push-4--xs grid__push-4--xxs grid__col-4--xs grid__col-4--xxs space-mt-2' : 'grid__col-6--xs grid__col-6--xxs'"
      >
        <a :href="logo.link" target="_blank">
          <vue-icon :name="`logo-${logo.name}`" classes="partners__item-svg" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { partners } from '~/config';
import VueIcon from '~/components/Icon';

export default {
  components: {
    VueIcon,
  },
  data() {
    const logos = partners.filter(p => p.name !== 'lexpress');
    logos.splice(-1, 1);

    return {
      logos,
    };
  },
};
</script>

<style lang="scss">
.partners__item {
  width: 150px;
  line-height: 0;
  text-align: center;
}

.partners__item-svg {
  /* max-width: 100px; */
  max-height: 50px;
  display: block;
  margin: auto;
}
</style>
